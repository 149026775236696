import React from 'react';
import { PageTitle } from '@westernmilling/react_component_library';

import logo from 'images/my_wm.png';

const LandingPage = () => (
  <img src={logo} width="100%" alt="logo" />
);

export default LandingPage;
