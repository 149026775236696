import React from 'react';
import { Col, Row } from 'reactstrap';
import { HelperFunctions, HtmlHelper, WmViewRow } from '@westernmilling/react_component_library';

const OrderSummary = (props) => {
  const destination = () => (
    props.authorized ? (
      <WmViewRow value={<pre>{props.item.destination_location}</pre>} field="destination_location" label="Ship To" />
    ) : <WmViewRow item={props.item} field="destination_location__name" label="Ship To" />
  );

  const col1 = () => (
    <Col lg={4} md={12}>
      <WmViewRow item={props.item} field="order_number" label="Order ID" />
      <WmViewRow item={props.item} field="status" label="Order Status" />
      <WmViewRow item={props.item} field="item__name_with_ref" label="Item" />
    </Col>
  );

  const col2 = () => (
    <Col lg={4} md={12}>
      <WmViewRow item={props.item} field="carrier_entity__name" label="Carrier" />
      {destination()}
    </Col>
  );

  const col3 = () => (
    <Col lg={4} md={12}>
      <WmViewRow item={props.item} field="item_quantity" label="Order Qty (Tons)" />
    </Col>
  );

  return (
    <Row>
      {col1()}
      {col2()}
      {col3()}
    </Row>
  );
};

export default OrderSummary;
