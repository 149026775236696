import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import {
  Api, CaretToggle, HelperFunctions, PageTitle,
} from '@westernmilling/react_component_library';

const Faq = (props) => {
  const [data, getData] = useState([]);
  const [toggle, getToggle] = useState({});

  const updateToggle = (key, value) => (
    getToggle((toggleState) => ({ ...toggleState, [key]: value }))
  );

  useEffect(() => {
    Api.crudList({ auth0: {} }, 'my_wm/my_wm_faqs').then((res) => {
      getData(res.data);
    });
  }, []);

  const questionDisplay = (sections, section) => (
    sections.attributes.map((faq, idx) => (
      <div key={`faq_${idx + 1}`} style={{ padding: '5px 10px' }}>
        <button
          type="button"
          style={{ all: 'unset' }}
          onClick={() => updateToggle(`showToggle${section}${idx}`, !toggle[`showToggle${section}${idx}`])}
        >
          <b>
            {faq.question}
            &nbsp;&nbsp;&nbsp;
          </b>
          <CaretToggle className="wm__no_padding" open={toggle[`showToggle${section}${idx}`]} />
        </button>
        <Collapse isOpen={toggle[`showToggle${section}${idx}`]} style={{ marginLeft: '20px' }}>
          {/* eslint-disable react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
          {/* eslint-enable react/no-danger */}
        </Collapse>
        {toggle[`showToggle${section}${idx}`] && idx + 1 < sections.attributes.length ? <hr /> : ''}
      </div>
    ))
  );

  const sectionDisplay = () => (
    data.map((d, index) => (
      <div key={`section_${index + 1}`}>
        <button
          type="button"
          style={{ all: 'unset' }}
          onClick={() => updateToggle(`showSection${index}`, !toggle[`showSection${index}`])}
        >
          <h6>
            {d.id}
            &nbsp;&nbsp;&nbsp;
            <CaretToggle className="wm__no_padding" open={toggle[`showSection${index}`]} />
          </h6>
        </button>
        <Collapse
          isOpen={toggle[`showSection${index}`]}
          style={{ backgroundColor: '#F0F0F0', border: 'solid #F0F0F0', borderRadius: '10px' }}
        >
          {questionDisplay(d, index)}
        </Collapse>
        <br />
      </div>
    ))
  );

  const faqDisplay = () => (
    <>
      <PageTitle title="FAQ" />
      <br />
      <Row>
        <Col md="3" xs="0" />
        <Col md="6" xs="12">
          {sectionDisplay()}
        </Col>
      </Row>
    </>
  );

  const pageDisplay = () => (
    HelperFunctions.isEmpty(data) ? <PageTitle title="FAQ" /> : faqDisplay()
  );

  return (
    <>
      {pageDisplay()}
    </>
  );
};

export default Faq;
