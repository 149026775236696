import React from 'react';
import { PageTitle } from '@westernmilling/react_component_library';

const PrivacyPolicy = () => (
  <div style={{ maxWidth: '1000px' }} className="mx-auto">
    <PageTitle title="Western Milling LLC Privacy Policy for SMS (Text) Messaging" />
    <p className="text-end">
      <b>
        <i>
          Last Updated September 9, 2024
        </i>
      </b>
    </p>
    <p>
      This Privacy Policy describes Western Milling, LLC’s (“WM” or “we”) data practices as they relate to SMS (text)
      messages. WM sends SMS messages to drivers to arrange for and facilitate transporting WM products. Message frequency
      may vary, and message and data rates may apply.
    </p>
    <p>
      Please review this Policy regularly, as we may amend it from time to time. When we do, we’ll notify you by revising
      the date at the top of this Privacy Policy.
    </p>
    <br />
    <p>
      <u>
        <b>
          Collection of Phone Numbers
        </b>
      </u>
    </p>
    <p>
      We collect your phone number when you or your employer provide it to us.
    </p>
    <br />
    <p>
      <u>
        <b>
          Use of Phone Numbers
        </b>
      </u>
    </p>
    <p>
      We may use your phone number to call you or send you SMS messages regarding order status, order changes,
      inventory status, or other information related to your transporting the WM product and/or the WM product you are
      transporting or will transport. We will not send you advertising or promotional SMS messages.
    </p>
    <br />
    <p>
      <u>
        <b>
          Disclosure of Phone Numbers
        </b>
      </u>
    </p>
    <p>
      We do not disclose phone numbers, or their related SMS consent, to third parties for marketing purposes
      on their own behalf.
    </p>
    <p>
      We may disclose your phone number as follows:
    </p>
    <p>
      <u>
        Service Providers:
      </u>
      <br />
      We may disclose your phone number to our service providers to facilitate our sending you SMS
      messages as described above. For example, we may disclose phone numbers to an SMS service provider for the sole
      purpose of that SMS service provider sending SMS messages on our behalf and otherwise administering our SMS program.
    </p>
    <p>
      <u>
        Legally Required
      </u>
      <br />
      or Reasonably Necessary: We may disclose your phone number if we reasonably believe we must
      disclose it to comply with legal or regulatory requirements, such as in response to a subpoena or other judicial
      process. We may disclose your phone number if it is reasonably necessary to protect the rights, property, or health
      or personal safety of an individual.
    </p>
    <p>
      <u>
        Corporate Transactions
      </u>
      <br />
      To maintain continuity of product transport, for lawfully required record-keeping, or for
      purposes of business continuity, in the event of a corporate acquisition, merger, or other corporate transaction,
      we may disclose your phone number.
    </p>
    <br />
    <p>
      <u>
        <b>
          Your Choices: Opt-out of Text Messages
        </b>
      </u>
    </p>
    <p>
      Although the SMS messages we send will be solely related to your transporting our products and not for advertising
      purposes, you can opt-out of SMS messages by replying to an SMS message with “STOP,” emailing us
      at
      {' '}
      <a href="mailto:privacy@westernmilling.com">privacy@westernmilling.com</a>
      , or calling us at (559) 302-1000.
    </p>
    <br />
    <p>
      <u>
        <b>
          Your Rights: State Law Privacy Rights
        </b>
      </u>
    </p>
    <p>
      Depending on where you reside, you may have the right to (1) request to know more about and access your personal
      data, (2) request deletion of your personal data, (3) request to transfer your personal data to a designated
      third-party (“portability”) and (4) request correction of inaccurate personal data.
    </p>
    <p>
      WM will not discriminate against you if your exercise your privacy rights.
    </p>
    <p>
      To designate an authorized agent to exercise privacy rights on your behalf, please provide the authorized agent
      with signed permission to submit a request on your behalf. If an authorized agent contacts us to exercise your
      privacy rights, we may need to contact you to verify your identity and protect the security of your personal data.
    </p>
    <p>
      To exercise any of your privacy rights or for further information about this SMS Privacy Policy, please contact
      us at
      {' '}
      <a href="mailto:privacy@westernmilling.com">privacy@westernmilling.com</a>
      {' '}
      or call us at (559) 302-1000.
    </p>
  </div>
);

export default PrivacyPolicy;
