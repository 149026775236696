import React from 'react';
import { Button } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import { Auth0Form, HelperFunctions } from '@westernmilling/react_component_library';

export default withAuth0(class UserInfo extends Auth0Form {
  constructor(props) {
    super(props);
    this.state.item = props.item;
  }

  formType = () => 'User Info';

  getCrudType = () => 'my_wm_users';

  auditLogs = () => '';

  successAlertMsg = (data) => (['Account settings updated successfully', 'success']);

  buildApiVariables = () => (
    { uuid: this.getItem().uuid, work_number: this.getItem().work_number, mobile_number: this.getItem().mobile_number }
  );

  updateData = (data) => {
    this.setState({ item: data.attributes });
    this.props.setHasMobileNumber(HelperFunctions.isNotEmpty(data.attributes.mobile_number));
  };

  submitButtons = () => (
    <Button className="wm__btn-outline-info" size="sm">
      <span className="small">Save Changes</span>
    </Button>
  );

  mobileRequired = () => HelperFunctions.isNotEmpty(this.props.item.opted_in_at);

  modalBodyRows = () => (
    <div>
      {this.getDisplayFieldRow('name', 'Name', this.getItem().name)}
      {this.getDisplayFieldRow('email', 'Email', this.getItem().email)}
      {this.getTextFieldRow('mobile_number', 'Mobile Number', this.mobileRequired())}
      {this.getTextFieldRow('work_number', 'Work Number', false)}
    </div>
  );
});
