import React, { Component } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';

class Auth0ProviderWithRedirect extends Component {
  domain = process.env.REACT_APP_AUTH0_DOMAIN;

  clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  redirect = `${process.env.REACT_APP_FULL_URL}/dashboard`;

  onRedirectCallback = (appState) => {
    let path = appState.returnTo;
    if (path.substring(0, 5) === '/mywm') {
      path = path.substring(5);
    }

    this.props.history.push(path);
  };

  render() {
    return (
      <Auth0Provider
        domain={this.domain}
        clientId={this.clientId}
        authorizationParams={{ redirect_uri: this.redirect }}
        onRedirectCallback={this.onRedirectCallback}
        audience={this.audience}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        {this.props.children}
      </Auth0Provider>
    );
  }
}

export default withRouter(Auth0ProviderWithRedirect);
