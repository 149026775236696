import React from 'react';
import { Col, Row } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Auth0Form, HelperFunctions, HtmlHelper, Notes, TimeSlots,
} from '@westernmilling/react_component_library';

import Summary from 'components/Forms/DispatchOrder/Summary';

export default withAuth0(class DispatchOrderAuthorized extends Auth0Form {
  formType = () => 'Order';

  shipInfo = () => (
    <>
      {HtmlHelper.divider()}
      <h4>
        Shipping Details
      </h4>
      <br />
      <Row>
        <Col lg={4} md={12}>
          {this.getRowWithValue('Pickup Location', <pre>{this.getItem().source_location}</pre>)}
        </Col>
        <Col lg={4} md={12}>
          {HelperFunctions.isEmpty(this.getItem().appointment) ? this.requestedDate() : this.appointmentDate()}
        </Col>
      </Row>
    </>
  );

  requestedDate = () => {
    const timeSlot = TimeSlots.GetTimeSlotName(this.getItem().time_slot, this.getItem().requested_time_slot_time);
    return this.getRowWithValue('Delivery Date', `${this.getItem().pickup_date} ${timeSlot}`);
  };

  appointmentDate = () => this.getRow('appointment', 'Appointment');

  modifyData = () => this.getItem().notes_on_order;

  tableNote = () => (
    <div>
      ATTENTION: Please note that Western Milling does not actively monitor notes; they are intended for reference only.
      For any questions regarding your order, please use the
      {' '}
      <a href={`${process.env.REACT_APP_FULL_URL}/contact_us`} target="_blank" rel="noreferrer">
        Contact Us
      </a>
      {' '}
      form.
    </div>
  );

  modalNote = () => (
    <div>
      {this.tableNote()}
      <br />
    </div>
  );

  notes = () => (
    <Notes
      authState={this.getAuth()}
      item={this.getItem()}
      modelType="DispatchOrder"
      notesShowing
      isAddAdmin
      hideBottomHr
      hideInternal
      modifyData={this.modifyData}
      closeOnZero
      showInternal={false}
      modalNote={this.modalNote()}
      tableNote={this.tableNote()}
    />
  );

  auditLogs = () => '';

  modalBodyRows = () => (
    <div>
      <Summary item={this.getItem()} authorized />
      {this.shipInfo()}
      <br />
      {this.notes()}
      <br />
    </div>
  );
});
