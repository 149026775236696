import { OptionsBaseApi } from '@westernmilling/react_component_library';

export default class MyWmContactSubjects extends OptionsBaseApi {
  getApiName = () => 'my_wm/my_wm_contact_subjects';

  getApiParams = () => ({ query: 'active = true' });

  getDropdownLabelText = () => 'Subject';

  getDropdownFieldName = () => this.props.fieldName || 'subject';

  getLabelFieldName = () => 'subject';

  additionalFields = () => ['email'];
}
