import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { User, WmSnackbarProvider } from '@westernmilling/react_component_library';

import Auth0ProviderWithRedirect from 'helpers/Auth0/Auth0ProviderWithRedirect';
import AccountSettingsView from 'containers/AccountSettingsView';
import ContactUs from 'containers/ContactUs';
import Dashboard from 'containers/Dashboard';
import DispatchOrderAuthorizedView from 'containers/DispatchOrderAuthorizedView';
import DispatchOrderView from 'containers/DispatchOrderView';
import Faq from 'containers/Faq';
import Footer from 'helpers/Footer';
import Header from 'helpers/Header';
import LandingPage from 'containers/LandingPage';
import OrderAuthorizedView from 'containers/OrderAuthorizedView';
import OrderListContainer from 'containers/OrderListContainer';
import OrderView from 'containers/OrderView';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import TermsAndConditions from 'containers/TermsAndConditions';

import './my_wm.css';

const pageStyle = (pathName) => {
  const path = document.location.pathname.split('/').filter((a) => a !== '');
  return path.length > 1 ? { padding: '20px', minHeight: '60vh' } : {};
};

const AppJs = () => (
  <Auth0ProviderWithRedirect>
    <Header />
    <WmSnackbarProvider>
      <div style={pageStyle()}>
        <Switch>
          <Route exact path="/account/:uuid" component={AccountSettingsView} />
          <Route exact path="/contact_us" component={ContactUs} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dispatch_orders/view/:order_number" component={DispatchOrderAuthorizedView} />
          <Route exact path="/do/:order_number" component={DispatchOrderView} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/orders/view/:order_number" component={OrderAuthorizedView} />
          <Route exact path="/o/:order_number" component={OrderView} />
          <Route exact path="/orders" component={OrderListContainer} />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} />
          <Route exact path="/terms_and_conditions" component={TermsAndConditions} />
          <Route default path="/" component={LandingPage} />
        </Switch>
      </div>
    </WmSnackbarProvider>
    <Footer />
  </Auth0ProviderWithRedirect>
);

export default AppJs;
