import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import PendingReleaseNumber from 'components/Tables/PendingReleaseNumber';
import TodaysOrders from 'components/Tables/TodaysOrders';
import TomorrowsOrders from 'components/Tables/TomorrowsOrders';
import UnshippedOrders from 'components/Tables/UnshippedOrders';

const Dashboard = (props) => (
  <div>
    <h2>
      Dashboard
    </h2>
    <br />
    <div className="wm__section_gray">
      <PendingReleaseNumber showToggle />
    </div>
    <br />
    <div className="wm__section_white">
      <UnshippedOrders showToggle />
    </div>
    <br />
    <div className="wm__section_gray">
      <TodaysOrders showToggle />
    </div>
    <br />
    <div className="wm__section_white">
      <TomorrowsOrders showToggle />
    </div>
  </div>
);

export default withAuthenticationRequired(Dashboard);
