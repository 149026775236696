import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { Auth0ViewContainer, PageTitle } from '@westernmilling/react_component_library';

import ItemView from 'components/Forms/DispatchOrder/Unauthorized';

export default withAuth0(class DispatchOrderView extends Auth0ViewContainer {
  constructor(props) {
    super(props);
    this.state.uuid = this.props.match.params.order_number;
    this.state.section = this.getSection();
  }

  getAuth = () => ({ auth0: {} });

  additionalButtonsAfterList = () => this.getItem().notes_text;

  getSection = () => (
    {
      type: 'my_wm/dispatch_orders_ua',
      form: ItemView,
      title: 'View Order',
      listRoute: '',
      listLabel: '',
      edit_admins: [],
    }
  );
});
