import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import OrderBaseList from 'components/Tables/OrderBaseList';

export default withAuth0(withAuthenticationRequired(class TodaysOrders extends OrderBaseList {
  getListParams = () => ({ todays_orders: 'Y', show_all: 'true' });

  getPageTitle = () => "Today's Orders";

  contentDisplay = () => this.getInnerTableContent();

  getTableClassSettings = () => 'wm_short_table';

  getStorageName = () => 'otto_my_wm_todays_orders_list';
}));
