import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import { Auth0Form, HelperFunctions, WmTextField } from '@westernmilling/react_component_library';
import moment from 'moment';

import TermsAndConditions from 'containers/TermsAndConditions';

export default withAuth0(class AcceptTermsModal extends Auth0Form {
  constructor(props) {
    super(props);
    this.state.item = props.item;
  }

  getCrudType = () => 'my_wm_users';

  auditLogs = () => '';

  successAlertMsg = (data) => (['Opted in successfully', 'success']);

  getModalSize = () => 'xl';

  getLabelColumns = () => 2;

  getFieldColumns = () => 10;

  submitLabel = () => 'Opt In';

  headerTitle = () => '';

  buildApiVariables = () => ({ uuid: this.getItem().uuid, opted_in_at_name: this.getItem().opted_in_at_name });

  signatureDisclaimer = () => (
    <div style={{ margin: '0px 50px' }}>
      By typing your name below, you acknowledge that it serves as your electronic signature, which holds the same
      legal significance as a handwritten signature, and confirms your agreement to the terms and conditions outlined:
    </div>
  );

  signatureFields = () => (
    <Row>
      <Col md="6" sm="12" className="text-start">
        <WmTextField
          fieldType="text"
          labelColumns={this.getLabelColumns()}
          fieldColumns={this.getFieldColumns()}
          text="Name"
          htmlFor="opted_in_at_name"
          name="opted_in_at_name"
          value={this.getItem().opted_in_at_name}
          onChange={this.onChange}
          isRequired
        />
      </Col>
      <Col md="5" sm="12">
        {this.getDisplayFieldRow('opted_in_at', 'Date', moment().format('MM/DD/YYYY'))}
      </Col>
    </Row>
  );

  privacyPolicy = () => (
    <div style={{ margin: '0px 50px' }}>
      Western Milling respects your right to privacy. You can
      {' '}
      <a href={`${process.env.REACT_APP_FULL_URL}/privacy_policy`} target="_blank" rel="noreferrer">
        view our privacy policy here
      </a>
      .
    </div>
  );

  modalBodyRows = () => (
    <div>
      <TermsAndConditions />
      <br />
      {this.signatureDisclaimer()}
      <br />
      {this.signatureFields()}
      <br />
      {this.privacyPolicy()}
      <br />
      <br />
    </div>
  );
});
