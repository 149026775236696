import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import OrderBaseList from 'components/Tables/OrderBaseList';

import Columns from 'helpers/columns/DashboardOrders';

export default withAuth0(withAuthenticationRequired(class PendingReleaseNumber extends OrderBaseList {
  getColumns = () => Columns(this, false, false);

  getListParams = () => ({ pending_release_number: 'Y', show_all: 'true' });

  getPageTitle = () => 'Pending Release #';

  contentDisplay = () => this.getInnerTableContent();

  getTableClassSettings = () => 'wm_short_table';

  getStorageName = () => 'otto_my_wm_orders_pending_release_list';
}));
