import React from 'react';
import {
  Col, FormGroup, Input, Row,
} from 'reactstrap';
import {
  AlertErrors, CrudForm, HelperFunctions, WmForm,
} from '@westernmilling/react_component_library';

import MyWmContactSubjects from 'components/Options/MyWmContactSubjects';

export default class ContactUs extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.preferred_contact ||= 'email';
  }

  formType = () => 'My WM Contact';

  getCrudType = () => 'my_wm/contact_us';

  successAlertMsg = () => 'Your submission has been received';

  getAuth = () => ({ auth0: {} });

  options = () => (
    {
      subjects: {
        component: MyWmContactSubjects,
        field: 'subject',
        props: { valueField: 'subject', isRequired: true },
      },
    }
  );

  handleSuccess = (data) => {
    this.setState({ showConfirmation: true });
  };

  getCheckbox = () => (
    this.inRow(
      <FormGroup>
        <Row className="align-items-center">
          <Col md={this.getLabelColumns()} />
          <Col md={this.getFieldColumns()}>
            <Input
              style={{ width: '1.5em', height: '1.5em' }}
              type="checkbox"
              className="wm__table_border float-start"
              htmlFor="send_email"
              name="send_email"
              onChange={this.onChange}
              disabled={HelperFunctions.isEmpty(this.getItem().email)}
            />
            <div className="float-start" style={{ padding: '5px 20px' }}>
              I would like to receive a copy of my message.
            </div>
          </Col>
        </Row>
      </FormGroup>,
    )
  );

  preferredContact = () => (
    this.getToggleRadioField(
      { label: 'Email', value: 'email' },
      { label: 'Phone', value: 'phone' },
      'preferred_contact',
      'Preferred Contact Method',
    )
  );

  viewPage = () => (
    this.state.showConfirmation === true ? this.confirmationInfo() : this.contactForm()
  );

  emailSendMessage = () => (
    this.getItem().send_email ? (
      <div className="text-center">
        A copy of this message has been emailed to you.
      </div>
    ) : ''
  );

  confirmationInfo = () => (
    <div>
      <div className="text-center">
        Thank you for contacting Western Milling, we have received the following information from you
        and will respond to your request within 2 business days.
      </div>
      {this.emailSendMessage()}
      <br />
      <br />
      {this.getTextRow('Name', this.getItem().name)}
      {this.getTextRow('Company', this.getItem().company)}
      {this.getTextRow('Prefered Contact Method', this.getItem().preferred_contact)}
      {this.getTextRow('Email', this.getItem().email)}
      {this.getTextRow('Phone', this.getItem().phone)}
      {this.getTextRow('Subject', this.getItem().subject)}
      {this.getTextRow('Message', <pre>{this.getItem().message}</pre>)}
    </div>
  );

  contactForm = () => (
    <WmForm submit={this.onSubmit} ref={this.formRef} id="form__crud">
      {this.getTextFieldRow('name', 'Name')}
      {this.getTextFieldRow('company', 'Company')}
      {this.getTextFieldRow('email', 'Email', this.getItem().preferred_contact === 'email')}
      {this.getTextFieldRow('phone', 'Phone', this.getItem().preferred_contact === 'phone')}
      {this.preferredContact()}
      {this.dropdownField('subjects')}
      {this.getTextareaRow('message', 'Message', true)}
      {this.getCheckbox()}
      <br />
      <Row>
        <Col md="12" sm="12" className="text-center">
          <AlertErrors errors={this.state.errors} />
        </Col>
      </Row>
      <div className="text-center">
        {this.getButton('Submit')}
      </div>
      <br />
    </WmForm>
  );
}
