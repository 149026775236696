import React from 'react';
import {
  Button, ButtonGroup, Col, Row,
} from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Api, Auth0ListContainer, HelperFunctions, ListFunctions, TableTitle, WmLabel,
} from '@westernmilling/react_component_library';

export default withAuth0(class OptInInfo extends Auth0ListContainer {
  getListApi = () => 'my_wm/my_wm_user_text_message_types';

  getStorageName = () => 'my_wm_users_text_message_types';

  getPageTitle = () => '';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  render() {
    return (
      <div>
        <TableTitle title={`Text Notification${this.props.needOptIn ? ' Opt In' : 's for'}`} />
        <br />
        {this.props.needOptIn ? this.needTerms() : this.textChoices()}
      </div>
    );
  }

  needTerms = () => (
    <div className="text-center">
      <Button className="wm__btn-outline-info" onClick={this.props.openTerms} disabled={!this.props.hasMobileNumber}>
        View Our Terms of Use and Conditions
      </Button>
      <br />
      <br />
      {this.props.hasMobileNumber ? '' : 'You must have a mobile number on file to receive text messages.'}
    </div>
  );

  hrLine = () => (
    <Row>
      <Col md="2" />
      <Col md="7">
        <hr />
      </Col>
    </Row>
  );

  textChoices = () => (
    this.state.data.map((row, idx) => (
      <div key={`text_${idx + 1}`}>
        { idx > 0 ? this.hrLine() : ''}
        <Row>
          <Col md="4" className="text-end wm__margin_top_10">
            <WmLabel id={`text_label_${idx + 1}`} text={row.label} />
          </Col>
          <Col md="2" className="text-center">
            {this.textMessageButton(row)}
          </Col>
        </Row>
      </div>
    ))
  );

  textMessageButton = (row) => (
    <ButtonGroup>
      {this.buildBtn(row, 'On', true)}
      {this.buildBtn(row, 'Off', false)}
    </ButtonGroup>
  );

  buildBtn = (row, text, value) => {
    const isOn = value === row.active;
    const color = isOn ? 'primary' : 'secondary';

    return (
      <Button color={color} disabled={isOn} onClick={() => this.updateTextMessage(row, value)}>
        <small>{text}</small>
      </Button>
    );
  };

  updateTextMessage = (row, value) => {
    const apiLink = HelperFunctions.isEmpty(row.uuid) ? 'crudCreate' : 'crudUpdate';
    Api[apiLink](this.getAuth(), this.getListApi(), { ...row, active: value }).then(
      (response) => HelperFunctions.handleStandardResponse(response, this.handleSuccess),
    );
  };

  handleSuccess = async () => {
    const data = await ListFunctions.fetchData(this.fetchDataOptions());
    this.setState(data);
    HelperFunctions.showAlert('Text message preference was updated successfully', { type: 'success' });
  };
});
