import React from 'react';
import { Col, Row } from 'reactstrap';
import { CrudForm, HelperFunctions, TimeSlots } from '@westernmilling/react_component_library';

import Summary from 'components/Forms/Order/Summary';

export default class OrderUnauthorizedViewForm extends CrudForm {
  formType = () => 'Order';

  ship1 = () => (
    <Col lg={4} md={12}>
      {this.getRow('source_location__name', 'Pickup Location')}
    </Col>
  );

  ship2 = () => (
    <Col lg={4} md={12}>
      {this.getRow('release__release_number', 'Release')}
    </Col>
  );

  ship3 = () => (
    HelperFunctions.isEmpty(this.getItem().appointment) ? this.requestedDate() : this.appointmentDate()
  );

  requestedDate = () => {
    const timeSlot = TimeSlots.GetTimeSlotName(this.getItem().time_slot, this.getItem().requested_time_slot_time);
    return this.getRowWithValue('Requested Date', `${this.getItem().requested_delivery_date} ${timeSlot}`);
  };

  appointmentDate = () => this.getRow('appointment', 'Appointment');

  manu1 = () => (
    <Col lg={4} md={12}>
      {this.getRow('manufacturing_started_at', 'Started At')}
    </Col>
  );

  manu2 = () => (
    <Col lg={4} md={12}>
      {this.getRow('manufacturing_completed_at', 'Completed At')}
    </Col>
  );

  manufactureDetails = () => (
    this.getItem().show_manufacturing ? (
      <div>
        <br />
        <hr />
        <br />
        <h4>
          Manufacturing Details
        </h4>
        <br />
        <Row>
          {this.manu1()}
          {this.manu2()}
        </Row>
      </div>
    ) : ''
  );

  divider = () => (
    <>
      <br />
      <hr />
      <br />
    </>
  );

  modalBodyRows = () => (
    <div>
      <Summary item={this.getItem()} />
      {this.divider()}
      <h4>
        Shipping Details
      </h4>
      <br />
      <Row>
        {this.ship1()}
        {this.ship2()}
        <Col lg={4} md={12}>
          {this.ship3()}
        </Col>
      </Row>
      {this.manufactureDetails()}
      <br />
      <br />
    </div>
  );
}
