import React from 'react';
import { Col, Row } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import { Auth0Form, HelperFunctions } from '@westernmilling/react_component_library';

import AcceptTermsModal from 'components/Forms/AccountSettings/AcceptTermsModal';
import OptInInfo from 'components/Forms/AccountSettings/OptInInfo';
import UserInfo from 'components/Forms/AccountSettings/UserInfo';

export default withAuth0(class AccountSettings extends Auth0Form {
  constructor(props) {
    super(props);
    this.state.hasMobileNumber = HelperFunctions.isNotEmpty(props.data[0]?.mobile_number);
  }

  formType = () => 'Account Settings';

  auditLogs = () => '';

  setHasMobileNumber = (hasMobileNumber) => this.setState({ hasMobileNumber });

  updateData = (data) => this.setState({ item: data.attributes });

  openTerms = () => this.setState({ showTerms: true });

  closeTerms = () => this.setState({ showTerms: false });

  needOptIn = () => HelperFunctions.isEmpty(this.getItem().opted_in_at);

  acceptTermsModal = () => (
    this.needOptIn() ? (
      <AcceptTermsModal
        item={this.getItem()}
        showModal={this.state.showTerms}
        closeModal={this.closeTerms}
        updateData={this.updateData}
      />
    ) : ''
  );

  modalBodyRows = () => (
    <div>
      <UserInfo item={this.getItem()} type="viewForm" setHasMobileNumber={this.setHasMobileNumber} />
      <br />
      <hr />
      <br />
      <OptInInfo
        type="viewForm"
        hasMobileNumber={this.state.hasMobileNumber}
        openTerms={this.openTerms}
        needOptIn={this.needOptIn()}
      />
      <br />
      {this.acceptTermsModal()}
    </div>
  );
});
