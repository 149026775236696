import React from 'react';
import { Col, Row } from 'reactstrap';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Auth0ViewContainer } from '@westernmilling/react_component_library';

import ItemView from 'components/Forms/AccountSettings';

export default withAuth0(withAuthenticationRequired(class AccountSettingsView extends Auth0ViewContainer {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
  }

  getSection = () => (
    {
      type: 'my_wm_users',
      form: ItemView,
      title: 'Account Settings',
      listRoute: '',
      listLabel: '',
      edit_admins: [],
    }
  );
}));
