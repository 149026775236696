import React from 'react';
import { Auth0ListContainer } from '@westernmilling/react_component_library';

import Columns from 'helpers/columns/DashboardOrders';

export default class OrderBaseList extends Auth0ListContainer {
  getColumns = () => Columns(this);

  getListApi = () => 'my_wm/orders';

  getStorageName = () => 'otto_my_wm_orders_list';

  getPageTitle = () => 'Orders';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  canDownloadCsv = () => true;
}
