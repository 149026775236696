import React, { Component } from 'react';
import { Nav, NavLink, NavItem } from 'reactstrap';

export default class Footer extends Component {
  getNavItem = (link, label) => {
    const div = (
      <NavItem className="navbar-text">
        <NavLink href={link}>{label}</NavLink>
      </NavItem>
    );
    return div;
  };

  render() {
    const base = `${process.env.REACT_APP_BASE_URL}/mywm${process.env.REACT_APP_URL_ENV}/`;
    return (
      <div id="wm_footer">
        <Nav className="navbar navbar-dark bg-dark justify-content-center">
          { this.getNavItem('https://www.westernmilling.com/', 'WesternMilling.com') }
          { this.getNavItem(`${base}faq`, 'FAQ') }
          { this.getNavItem(`${base}privacy_policy`, 'SMS Privacy Policy') }
          { this.getNavItem(`${base}terms_and_conditions`, 'Terms of Use') }
          { this.getNavItem(`${base}contact_us`, 'Contact Us') }
        </Nav>
      </div>
    );
  }
}
