import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import OrderBaseList from 'components/Tables/OrderBaseList';

import Columns from 'helpers/columns/Orders';

export default withAuth0(withAuthenticationRequired(class OrderListContainer extends OrderBaseList {
  getColumns = () => Columns(this);

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();
}));
