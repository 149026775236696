import React from 'react';
import { Links, TableFilters, TimeSlots } from '@westernmilling/react_component_library';

const OrderColumns = (context) => [
  {
    Header: 'Order #',
    accessor: 'order_number',
    Cell: (row) => {
      const type = row.original.type === 'sales_order' ? 'orders' : 'dispatch_orders';
      return Links.getLink('mywm', `${type}/view/${row.original.order_number}`, row.original.order_number);
    },
    className: 'text-center',
    maxWidth: 125,
  },
  {
    Header: 'Pickup Date',
    Filter: ({ filter, onChange }) => TableFilters.DateRangeTableFilter(filter, onChange),
    accessor: 'requested_delivery_date',
    className: 'text-center',
    width: 180,
  },
  {
    Header: 'Release # / Appointment',
    accessor: 'release_number',
    className: 'text-left',
  },
  {
    Header: 'Source',
    accessor: 'source_location',
    className: 'text-left',
  },
  {
    Header: 'Carrier',
    accessor: 'carrier_name',
    className: 'text-left',
  },
  {
    Header: 'Ship To',
    accessor: 'destination_location',
    className: 'text-left',
  },
  {
    Header: 'Item',
    accessor: 'item_name',
    className: 'text-left',
  },
  {
    Header: 'Quantity (tons)',
    accessor: 'quantity',
    className: 'text-center',
    maxWidth: 115,
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-left',
    maxWidth: 140,
  },
];

export default OrderColumns;
