import React from 'react';
import { PageTitle } from '@westernmilling/react_component_library';

const TermsAndConditions = () => (
  <div style={{ maxWidth: '1000px' }} className="mx-auto">
    <PageTitle title="Website Terms of Use" />
    <p className="text-end">
      <b>
        <i>
          Last updated: September 11, 2024
        </i>
      </b>
    </p>
    <p>
      These Terms of Use (“Terms”) govern your use of Western Milling’s websites, available at
      {' '}
      <a href="https://www.westernmilling.com/">www.westernmilling.com</a>
      {' '}
      and
      {' '}
      <a href="https://www.my-westernmilling.com/mywm">www.my-westernmilling.com</a>
      {' '}
      {' '}
      (“Sites”). Please review these Terms regularly, as we may revise them from time to time.
      When we make changes, we’ll update the date at the top of these Terms.
    </p>
    <p>
      THESE TERMS INCLUDE AN ARBITRATION AGREEMENT AND A CLASS ARBITRATION WAIVER THAT AFFECT YOUR RIGHTS. PLEASE REVIEW
      CAREFULLY THE DETAILS BELOW.
    </p>
    <p>
      <u>
        <b>
          Eligibility
        </b>
      </u>
      <br />
      You must be 16 years of age to use the Sites. If you are not yet 16 years old, do not use these Sites.
    </p>
    <p>
      <u>
        <b>
          General
        </b>
      </u>
      <br />
      By using these Sites, you acknowledge and agree to these Terms. If you do not agree to these Terms, do not use this
      website. This website is made available to you for your individual use; commercial use is prohibited.
    </p>
    <p>
      <u>
        <b>
          Content
        </b>
      </u>
      <br />
      Any graphics, design, photographs, videos, audio, information, data, computer code, materials, and content of
      the Sites, the Sites as a whole, and all other elements of the Sites (collectively, &quot;Contents&quot;) are
      protected by copyright, trademark, trade dress, patent, or other intellectual property rights. All Contents
      are owned, controlled, or licensed by WM or its licensors. Any use of the Contents without WM’s express written
      consent is strictly prohibited.
    </p>
    <p>
      WM grants you a limited license to access and use the Sites and the Content solely for your personal, noncommercial
      use. Except as noted herein, you may not reproduce, publish, transmit, distribute, display, modify, create derivative
      works from, sell or participate in any sale of, or exploit in any way, any of the Contents or the Sites.
    </p>
    <div>
      <u>
        <b>
          Acceptable Use
        </b>
      </u>
      <br />
      When you access or use these Sites, you are prohibited from:
      <ul>
        <li>
          Violating any law or regulation
        </li>
        <li>
          Data mining or otherwise using software designed to collect data from the Sites
        </li>
        <li>
          Altering or tampering with any Content on the Sites
        </li>
        <li>
          Disrupting, attacking, or modifying the Sites or its associated software, hardware, or servers in any way
        </li>
      </ul>
    </div>
    <p>
      <u>
        <b>
          Indemnification
        </b>
      </u>
      <br />
      You agree to defend, indemnify, and hold harmless WM, its affiliates, and their respective directors, officers,
      employees, and agents against any losses, liabilities, claims, and expenses (including attorney’s fees) as a result
      of (i) your access to or use of the Sites; (ii) your breach or alleged breach of these Terms; (iii) your violation
      of any third-party right, including, without limitation, any intellectual property right,
      publicity, confidentiality, property, or privacy right; (iv) your violation of any laws, rules, regulations, codes,
      statutes, ordinances, or orders of any governmental and quasi-governmental authorities, including, without limitation,
      all regulatory, administrative, and legislative authorities; or (v) any misrepresentation made by you. This means
      that you will be responsible for any loss or damage we suffer due to your breach of these Terms.
    </p>
    <p>
      <u>
        <b>
          Warranties, Disclaimer, and Limitation of Liability
        </b>
      </u>
      <br />
      THESE SITES AND THEIR CONTENT ARE PROVIDED ON AN &quot;AS IS&quot; BASIS. WM DISCLAIMS ALL WARRANTIES OF ANY KIND,
      EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
      PARTICULAR PURPOSE. WM DOES NOT WARRANT THAT YOUR USE OF THESE SITES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT
      THESE SITES OR THEIR SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL ELEMENTS. ALTHOUGH WM ENDEAVORS TO PROVIDE
      ACCURATE INFORMATION, IT DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE ACCURACY OR RELIABILITY OF
      INFORMATION ON THESE SITES.
    </p>
    <p>
      YOUR USE OF THESE SITES IS AT YOUR OWN RISK. NEITHER WM NOR ITS AFFILIATED OR RELATED ENTITIES OR CONTENT PROVIDERS
      SHALL BE LIABLE TO ANY PERSON OR ENTITY FOR ANY DIRECT OR INDIRECT LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL,
      PUNITIVE, SPECIAL OR OTHERWISE), INJURY, CLAIM, OR LIABILITY OF ANY KIND OR CHARACTER WHATSOEVER BASED UPON OR
      RESULTING FROM YOUR USE OR INABILITY TO USE THESE SITES. YOU MUST NOTIFY ANY CLAIM TO WM WITHIN 12 MONTHS OF ITS
      DISCOVERY.
    </p>
    <p>
      <u>
        <b>
          Dispute Resolution
        </b>
      </u>
      <br />
      <u>
        Arbitration
      </u>
      <br />
      Any controversy, claim or dispute arising out of or relating to this Agreement, shall be settled by binding
      arbitration in Tulare County, California.  Such arbitration shall be conducted in accordance with and subject
      to the provisions of the California Code of Civil Procedure relating to arbitration as amended from time to time.
      If required by law, the Company shall bear the costs of the arbitration, including the arbitrator&apos;s fees.
      Each party shall be responsible for compensating their own attorneys and witnesses unless the arbitrator orders
      otherwise. If the parties cannot agree upon an arbitrator, the Tulare County Superior Court shall appoint the
      arbitrator. The parties agree to abide by all decisions and awards rendered in such proceedings.  Such decisions
      and awards rendered by the arbitrators shall be final and conclusive and may be entered in any court having
      jurisdiction thereof as a basis of judgment and of the issuance of execution for its collection.  All such
      controversies, claims or disputes shall be settled in this manner in lieu of any action at law or equity,
      provided however, that nothing in this subsection shall be construed as precluding brining an action for
      injunctive relief or other equitable relief.  The arbitrators shall not have the right to award punitive damages
      or speculative damages to either party and shall not have the power to amend this Agreement.
    </p>
    <p>
      <u>
        No Class Arbitrations
      </u>
      <br />
      Arbitration will only proceed solely on an individual basis; these Terms do not permit class arbitration, or any
      claims brought as a plaintiff or class member in any class or representative arbitration proceeding.
      Notwithstanding the foregoing, the arbitral tribunal may consolidate more than one person&apos;s claims or otherwise
      preside over a form of a representative or class proceeding if all parties consent in writing.
    </p>
    <p>
      <u>
        Opt Out of Arbitration
      </u>
      <br />
      You have may opt out of this agreement to arbitrate by providing written notice of your intent to opt out within 60
      days of visiting this website. You can contact us as set forth below.
    </p>
    <p>
      <u>
        <b>
          Governing Law and Venue
        </b>
      </u>
      <br />
      Law governing the interpretation of these Terms, and any dispute related to the Terms or Sites are the Federal
      Arbitration Act, applicable federal laws, and the laws of the State of California. Venue will be in Tulare
      County, California.
    </p>
    <p>
      <u>
        <b>
          Entire Agreement
        </b>
      </u>
      <br />
      These Terms constitute the entire agreement between you and WM regarding access and use of these Sites,
      and their Contents.
    </p>
    <p>
      <u>
        <b>
          Contact Us
        </b>
      </u>
      <br />
      If you have questions about these Sites or their Contents, please contact us
      via
      {' '}
      <a href="mailto:info@westernmilling.com">info@westernmilling.com</a>
      , or write to us at:
      <br />
      <br />
      Western Milling
      <br />
      Attn: Legal
      <br />
      31120 West Street
      <br />
      Goshen, CA 93227.
    </p>
  </div>
);

export default TermsAndConditions;
