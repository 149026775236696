import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button, Col, Nav, Navbar, NavLink, NavbarBrand, Row, UncontrolledAlert,
} from 'reactstrap';

import { Api } from '@westernmilling/react_component_library';

import LoginButton from 'helpers/Header/LoginButton';
import Links from 'helpers/Header/Links';

import logo from 'images/MyWesternMilling_large.png';

const Header = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const env = process.env.REACT_APP_TITLE_ENV;
  const redirectUrl = process.env.REACT_APP_FULL_URL + process.env.REACT_APP_URL_ENV;

  const getNavBarClass = () => (
    env === 'production'
      ? 'navbar-dark bg-dark box-shadow border-bottom align-items-center'
      : 'navbar-dark wm__dev_background align-items-center'
  );

  const getTitleText = () => (env !== 'production' ? <h4>{env.toUpperCase()}</h4> : '');

  const clearApiHost = () => {
    Api.clearApiHost();
    window.location.reload(true);
  };

  return (
    <div id="wm_header">
      <Navbar className={getNavBarClass()} style={{ paddingLeft: '25px', paddingRight: '25px' }}>
        <NavbarBrand href={redirectUrl}>
          <img src={logo} height="30" alt="logo" />
        </NavbarBrand>
        { getTitleText() }
        <Nav>
          {isAuthenticated ? <Links /> : <LoginButton />}
        </Nav>

      </Navbar>
      {Api.localStorageHost()
        ? (
          <span>
            <small className="wm__margin_right">
              Host:
              {Api.localStorageHost()}
            </small>
            <Button color="secondary" size="sm" onClick={clearApiHost}>
              clear
            </Button>
          </span>
        ) : ''}
    </div>
  );
};

export default Header;
