import React from 'react';
import { Col, Row } from 'reactstrap';
import { HelperFunctions, HtmlHelper, WmViewRow } from '@westernmilling/react_component_library';

const OrderSummary = (props) => {
  const certLink = (url, certNum) => (
    <div key={url}><a href={url} rel="noopener noreferrer" target="_blank">{certNum}</a></div>
  );

  const certDisplay = () => (
    props.authorized && HelperFunctions.isNotEmpty(props.item.weight_certificate_url)
      ? certLink(props.item.weight_certificate_url, props.item.weight_certificate_number)
      : props.item.weight_certificate_number
  );

  const destination = () => (
    props.authorized ? (
      <WmViewRow value={<pre>{props.item.destination_location}</pre>} field="destination_location" label="Ship To" />
    ) : <WmViewRow item={props.item} field="destination_location__name" label="Ship To" />
  );

  const col1 = () => (
    <Col lg={4} md={12}>
      <WmViewRow item={props.item} field="order_number" label="Order ID" />
      <WmViewRow item={props.item} field="status" label="Order Status" />
      <WmViewRow item={props.item} field="item__name_with_ref" label="Item" />
    </Col>
  );

  const col2 = () => (
    <Col lg={4} md={12}>
      <WmViewRow item={props.item} field="carrier_entity__name" label="Carrier" />
      {destination()}
    </Col>
  );

  const col3 = () => (
    <Col lg={4} md={12}>
      <WmViewRow item={props.item} field="item_quantity" label="Order Qty (Tons)" />
      <WmViewRow item={props.item} field="ship_weight_in_tons" label="Actual Qty (Tons)" />
      <WmViewRow item={props.item} field="scale_date" label="Scale Date" />
      <WmViewRow value={certDisplay()} item={props.item} field="weight_certificate_number" label="Weight Certificate" />
    </Col>
  );

  return (
    <Row>
      {col1()}
      {col2()}
      {col3()}
    </Row>
  );
};

export default OrderSummary;
