import React from 'react';
import { Col, Row } from 'reactstrap';
import { withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Auth0ViewContainer, CrudView, HelperFunctions, PageTitle,
} from '@westernmilling/react_component_library';

import ItemView from 'components/Forms/Order/Authorized';

export default withAuth0(withAuthenticationRequired(class OrderAuthorizedView extends Auth0ViewContainer {
  constructor(props) {
    super(props);
    this.state.uuid = this.props.match.params.order_number;
    this.state.section = this.getSection();
  }

  getSection = () => (
    {
      type: 'my_wm/orders',
      form: ItemView,
      title: 'View Order',
      listRoute: '',
      listLabel: '',
      edit_admins: [],
    }
  );
}));
