import React from 'react';
import { PageTitle } from '@westernmilling/react_component_library';

import ContactUsForm from 'components/Forms/ContactUs';

const ContactUs = () => (
  <>
    <PageTitle title="Contact Us" />
    <br />
    <ContactUsForm type="view" />
  </>
);

export default ContactUs;
